import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'

const TitleContainer = styled.div`
  width: calc(100% - 4rem);
  display: flex;
  align-items: center; 
  margin-left: -4rem;
  padding-top: 1.65rem;
  margin-right: 3rem; 
  border-bottom: 1px solid #979797;
  overflow:hidden;
  background-color: white;
  position: fixed;
  z-index: 10;
`
const PageTitle = styled.div`
font-family: Poppins;
font-style: normal;
font-size: 2.2rem;
font-weight: 600;
margin-bottom: 1.1rem;
height: auto;
`
const ImageTitle = styled.img`
  padding-right: 1rem;
  width: auto;
`

const Title = (props) => {
  return (
    <TitleContainer>
      <PageTitle>
        <ImageTitle style={{ height: `${props.heightImg || 1.6}rem` }} src={props.icon} />
        {props.text}
      </PageTitle>
    </TitleContainer>
  )
}

export default Title
