import React from 'react'
import { useSelector } from 'react-redux'
import {DashboardContainer, Box, IconFilter, FilterText} from './styledComponents'
import UiActif from '../../imagesPP/actif.svg'
import UiCar from '../../imagesPP/Car.svg'
import UiBoy from '../../imagesPP/boy.svg'

const Dashboard = ({ height, type, data }) => {
    const dashboard = useSelector((state) => state.toJS().global.dashboard || {})
    const fullDashboard = (type ==='simuPlayers' || type === 'agencies') ? true : false
    

    return(
        <DashboardContainer style={{
            position: 'unset',
            top: type === 'agencies' ? '-91px' : '16px',
            left: type === 'agencies' ? '0px' : '65px',
            height: height,
            width: fullDashboard ? 'unset' : '530px'
          }}>
          {fullDashboard ? 
            <>
              <Box style={{borderRight: '1px solid black'}}>
                <IconFilter src={UiBoy} />
                <FilterText>
                    <p style={{fontWeight: 'bold'}}>{dashboard.totalStudents}</p>
                    <p>{'\xa0 élèves au total'}</p>
                </FilterText>
              </Box>
              <Box style={{borderRight: '1px solid black'}}>
                <IconFilter style={{height: '12px'}} src={UiActif}/>
                <FilterText>
                  <p style={{fontWeight: 'bold'}}>{dashboard.totalActiveStudents}</p>
                  <p>{'\xa0 élèves actifs'}</p>
                </FilterText>
              </Box>
              <Box>
                <IconFilter src={UiCar}/>
                <FilterText>
                    <p style={{fontWeight: 'bold'}}>{dashboard.totalInactiveStudents}</p>
                    <p>{'\xa0 élèves non transférés'}</p>
                </FilterText>
              </Box>
            </>
          :
            <Box>
              <IconFilter src={UiBoy} />
              <FilterText>
                  <p style={{fontWeight: '600', fontSize: '15px', fontFamily: 'Poppins'}}>{data.firstName + ' ' + data.lastName}</p>
              </FilterText>
            </Box>
        }
        </DashboardContainer>
    )
  }

export default Dashboard
